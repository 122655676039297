#employerWhy {
  h1 {
    line-height: 1.4em;
  }

  #footer {
    margin-top: 0;
    z-index: 400;
    position: relative;
  }
  p {
    max-width: 700px;
  }
  .bigIcon {
    @include media-breakpoint-down(md) {
      width: 150px;
      height: 150px;
    }
  }

  .testimonial {
    @include media-breakpoint-down(md) {
      border: none;
    }

    .quote {
      position: initial;
    }
    p.quoted {
      margin-top: 10px;
      strong {
        display: block;
        font-size: 32px;
        @include media-breakpoint-down(md) {
          font-size: 22px;
        }
      }
      &:before {
        content: " ";
        background-image: url("/icons/quote.svg");
        font-weight: 700;
        background-size: cover;
        display: inline-block;
        width: 25px;
        height: 25px;
        margin-bottom: 10px;
        // transform: scale(1.8);
        @include media-breakpoint-down(md) {
          width: 20px;
          height: 20px;
          font-size: 32px;
          position: absolute;
          top: 0;
        }
      }
    }
  }

  button.cta.small {
    @include media-breakpoint-up(xl) {
      font-size: 12px;
    }
  }

  ul.bullets {
    list-style: none;
    padding: 0;
    li {
      min-width: 220px;
      padding-left: 1.8em;
      &:before {
        position: relative;
        color: $primary;
        content: "\25A0";
        font-size: 10px;
        font-weight: 800;
        display: inline-block;
        margin-left: -1.8em;
        width: 1.8em;
        top: -0.2em;
      }
      p {
        display: inline;
      }
    }
  }

  .colHeight {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-align: center;
  }
}
