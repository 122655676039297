#faqLayout {
  .imageContainer {
    position: relative;
    width: 100%;
    height: 400px;
    @include media-breakpoint-down(md) {
      height: 250px;
    }
  }
}

.topicsContainer {
  a {
    text-decoration: none;
  }

  h4 {
    color: $gray900;
    margin-bottom: 0;
  }
}