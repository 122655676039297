$primary: #e2001a;
$Syn-darkRed: #960011;
$border-radius: 0px;
$border-radius-lg: 0px;
$border-radius-sm: 0px;

$gray100: #f8f9fa;
$gray200: #e9ecef;
$gray300: #dee2e6;
$gray400: #ced4da;
$gray500: #adb5bd;
$gray600: #6c757d;
$gray700: #495057;
$gray800: #2f3336;
$gray900: #212529;

$green100: #f0fff4;
$green200: #c6f6d5;
$green300: #9ae6b4;
$green400: #68d391;
$green500: #48bb78;
$green600: #38a169;
$green700: #2f855a;
$green800: #276749;
$green900: #22543d;

$red100: #fff5f5;
$red200: #fed7d7;
$red300: #feb2b2;
$red400: #fc8181;
$red500: #f56565;
$red600: #e53e3e;
$red700: #c53030;
$red800: #9b2c2c;
$red900: #742a2a;

$yellow: #ffb400;
$dark: #2f3336;
$accent: #2ec4b6;
$blue: #222e50;
$mutedgray: #717f8a;
$mutedaccent: #f1fcfb;
$hoveraccent: #dff7f5;

$font-size-base: 1rem !default;
$h1-font-size: $font-size-base * 2 !default;
$h2-font-size: $font-size-base * 1.5 !default;
$h3-font-size: $font-size-base * 1.4 !default;
$h4-font-size: $font-size-base * 1.3 !default;
$h5-font-size: $font-size-base * 1.2 !default;
$h6-font-size: $font-size-base !default;

$navbar-light-color: rgba(#000, 1);
$navbar-light-toggler-icon-bg: str-replace(
  url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-light-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"),
  "#",
  "%23"
) !default;

// Used to override bootstrap default arrow down icons with dropdowns
$enable-caret: false;
$headings-font-weight: 600;

$theme-colors: (
  "primary": $primary,
  "secondary": $gray600,
  "success": $green700,
  "info": $accent,
  "warning": $yellow,
  "danger": $red700,
  "light": $gray100,
  "dark": $gray900,
  "accent": $accent,
  ) !default;