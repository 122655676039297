#faqPage {
  .question {
    cursor: pointer;
    position: relative;
    .icon {
      position: absolute;
      bottom: 12px;
      display: inline-block;
      right: 8px;
      transition: all 0.2s ease-in-out;
    }

    &:hover {
      border-bottom: 1px solid $gray400 !important;
      .icon {
        right: 0px;
        color: #e2001a;
      }
    }
  }
}
