#indexPage {
  max-width: 1920px;
  margin: 0 auto;

  h2 {
    text-transform: uppercase;
    strong {
      color: $primary;
    }
  }

  .container {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  .callToActions {
    padding-top: 0;
  }

  .lg-50 {
    @include media-breakpoint-up(lg) {
      min-height: calc(55vh - 45px);
      position: relative;
      max-width: 480px;
      &:nth-of-type(1) {
        margin-left: auto;
      }
      &:nth-of-type(2) {
        margin-right: auto;
      }
    }
    @include media-breakpoint-up(xl) {
      max-width: 570px;
    }
  }

  #index-banner {
    background-image: url("/header_home_1920a.jpg");
    background-size: cover;
    background-position: 50% 0%;
    margin-bottom: 0;
    position: relative;
    @include media-breakpoint-up(lg) {
      min-height: 45vh;
    }
  }

  #index-jobSearch {
    min-height: 110px;
    @include media-breakpoint-up(lg) {
      max-width: 960px;
      margin-bottom: -110px;
    }
    @include media-breakpoint-up(xl) {
      max-width: 1140px;
    }
    .indexJobSearchForm {
      display: flex;
      align-items: center;
      background-origin: border-box;
      @include media-breakpoint-up(lg) {
        top: -55px;
        .form-group {
          margin-bottom: 0;
        }
      }
    }
  }

  .indexCompanies h2 {
    margin-bottom: 32px;
  }

  .eventTeaser {
    .minHeightTitle {
      min-height: 60px;
    }
  }

  @include media-breakpoint-down(lg) {
    h1 {
      font-size: 24px;
    }
    #index-mainTitle {
      min-height: calc(40vh - 49px);
    }
    #index-banner {
      min-height: 40vh;
      padding: 0;
    }
    #index-jobSearch {
      min-height: 20vh;
      padding: 0;
      .indexJobSearchForm {
        height: 100%;
        padding: 20px 0;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .blogpostTeaser.card {
      margin-bottom: 0;
    }
    .fourGrid {
      min-width: 1px;
      .col-lg-6:nth-of-type(1),
      .col-lg-6:nth-of-type(2) {
        margin-bottom: 20px;
      }
    }
  }
}
